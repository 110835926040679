import { useState } from "react";
import axios from "axios";
import styles from "./Contact.module.scss";

const Contact = (props) => {
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const sendEmail = async () => {
    setDisabled(true);

    let re = await axios
      .post(
        "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
        {
          fromEmail: "admin@shmili.com",
          toEmail: "info@propupgroup.com",
          subject: "Email contact from techvalleyflexpark.com",
          data: [
            { label: "Name", value: name },
            { label: "Business Name", value: businessName },
            { label: "Email", value: email },
            { label: "Phone", value: phone },
            { label: "Message", value: message },
          ],
        }
      )
      .catch((e) => e);

    // eslint-disable-next-line
     let re2 = await axios
      .post(
        "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
        {
          fromEmail: "admin@shmili.com",
          toEmail: "meghanoconnor@kw.com",
          subject: "Email contact from techvalleyflexpark.com",
          data: [
            { label: "Name", value: name },
            { label: "Business Name", value: businessName },
            { label: "Email", value: email },
            { label: "Phone", value: phone },
            { label: "Message", value: message },
          ],
        }
      )
      .catch((e) => e);

    if (re.data === "OK") {
      setName("");
      setBusinessName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }

    setDisabled(false);
    // https://emailsenderprod.azurewebsites.net/api/emailSenderProduction
  };
  return (
    <div className={styles.contact}>
      <div className={`${styles.formWrpr}`}>
        <div className={`df ${styles.formInnerWrpr}`}>
          <div className={`${styles.infoWrpr}`}>
            <h3 className={`ffmb fs35`}>Contact Us</h3>

            <p className={`ffmb fs15 ${styles.label}`}>Phone</p>
            <p className={`ffml fs12 ${styles.value}`}>518.267.1240</p>

            <p className={`ffmb fs15 ${styles.label}`}>Email</p>
            <p className={`ffml fs12 ${styles.value}`}>info@propupgroup.com</p>

            <p className={`ffmb fs15 ${styles.label}`}>Location</p>
            <p className={`ffml fs12 ${styles.value}`}>
              22 - 27 Wood road, Round Lake, NY
            </p>
          </div>
          <div className={`${styles.inputsWrpr}`}>
            <div className={`${styles.inputWrpr}`}>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className={`${styles.input}`}
              />
            </div>
            <div className={`${styles.inputWrpr}`}>
              <input
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="Business Name"
                className={`${styles.input}`}
              />
            </div>
            <div className={`${styles.inputWrpr}`}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className={`${styles.input}`}
              />
            </div>
            <div className={`${styles.inputWrpr}`}>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                className={`${styles.input}`}
              />
            </div>

            <div className={`${styles.inputWrpr}`}>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
                className={`${styles.input}`}
              />
            </div>
            <div className={`df ${styles.inputWrpr}`}>
              <button
                onClick={sendEmail}
                disabled={disabled}
                className={`mla ${styles.submitBtn}`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
